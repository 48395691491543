<template>
  <div v-if="isshowgrid">
    <va-card title="Content List">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex xs12 md3 offset--md3">
          <va-button color="success" icon="fa fa-plus" @click="add()">Add</va-button>
        </div>
      </div>
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        clickable
      >
        <template slot="status" slot-scope="props">
          <va-button data-toggle="tooltip" title="Video Failed!" v-if="props.rowData.video_status === 'Error'" flat medium color="red" icon="fa fa-exclamation-circle" class="ma-0"></va-button>
          <va-button data-toggle="tooltip" title="Video Success!" v-if="(props.rowData.video_status == 'Finished') || (props.rowData.video_status == 'Busy')" flat medium color="green" icon="fa fa-check-circle-o" class="ma-0"></va-button>
          <va-button data-toggle="tooltip" title="Build in progress!" v-if="props.rowData.video_status === 'Progress'" flat medium color="blue" icon="fa fa-spinner fa-pulse" class="ma-0"></va-button>
        </template>
        <template slot="actions" slot-scope="props">
          <va-button v-if="(props.rowData.type == 'Movie' && props.rowData.video_status == 'Finished') || (props.rowData.video_status == 'Busy') || (props.rowData.video_status == 'Error')" flat small color="gray" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0"></va-button>
        </template>
      </va-data-table>
    </va-card>
  </div>
  <div v-else-if='isshowForm'>
    <div>
      <va-card :title="header_title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <va-select
              v-if = "isCreate"
              label="Type *"
              placeholder="Select Type"
              v-model="type"
              @input="typeInput()"
              textBy="id"
              :options="type_obj"
              :error="!!typeErrors.length"
              :error-messages="typeErrors"
              noClear
            />
            <va-input
              v-if = "isUpdate"
              v-model="type"
              type="Type"
              label="Type"
              :disabled="true"
            />
            <va-input
              v-model.trim="title"
              type="text"
              label="Title *"
              placeholder="Enter Title "
              pattern="^[A-Za-z0-9]+(?: +[A-Za-z0-9]+)*$"
              :error="!!titleErrors.length"
              :error-messages="titleErrors"
            />
            <va-input
              v-model.trim="description"
              type="text"
              label="Description *"
              placeholder="Enter Description"
              pattern="^[A-Za-z0-9]+(?: +[A-Za-z0-9]+)*$"
              :error="!!descriptionErrors.length"
              :error-messages="descriptionErrors"
            />
            <va-select
              label="language *"
              v-model="language"
              textBy="id"
              searchable
              :options="language_data"
              :error="!!languageErrors.length"
              :error-messages="languageErrors"
            />
            <va-select
              v-if="movieFlag"
              v-model.trim="age_limit"
              label="Age Limit *"
              textBy="id"
              :options="age_data"
              :error="!!agelimitErrors.length"
              :error-messages="agelimitErrors"
            />
            <va-input
              v-if="movieFlag"
              v-model.trim="actor"
              type="text"
              label="Actor *"
              pattern="^[A-Za-z0-9]+(?: +[A-Za-z0-9]+)*$"
              placeholder="Enter actor"
              :error="!!actorErrors.length"
              :error-messages="actorErrors"
            />
            <va-input
              v-if="movieFlag"
              v-model.trim="director"
              type="text"
              label="Director *"
              pattern="^[A-Za-z0-9]+(?: +[A-Za-z0-9]+)*$"
              placeholder="Enter Director"
              :error="!!directorErrors.length"
              :error-messages="directorErrors"
            />
            <va-select
              v-if = "liveFlag"
              label="URL Type *"
              placeholder="Select URL Type"
              v-model="url_type"
              textBy="id"
              @input="urlInput(url_type)"
              :options="urltype_obj"
              :error="!!urltypeErrors.length"
              :error-messages="urltypeErrors"
              noClear
            />
            <va-input
              v-if="liveFlag"
              v-model.trim="url"
              type="text"
              label="URL *"
              pattern="^[A-Za-z0-9]+(?: +[A-Za-z0-9]+)*$"
              :placeholder="url_placeholder"
              :error="!!urlErrors.length"
              :error-messages="urlErrors"
            />
            <div v-if="movieFlag">
              <label class="date_picker_label">Release date *</label>
              <datepicker
                class="date_picker"
                v-model="releasedate "
                format="MMM dd yyyy"
                :error="!!releasedateErrors.length"
                :error-messages="releasedateErrors"
              />
            </div>
            <div class="flex xs12">
              <va-card title="*Horizontal Image">
                <va-file-upload
                  type="single"
                  dropzone
                  @input="CheckFile('horizontal_image')"
                  v-model="horizontal_image"
                />
              </va-card>
              <va-card title="*Vertical Image">
                <va-file-upload
                  type="single"
                  dropzone
                  @input="CheckFile('vertical_image')"
                  v-model="vertical_image"
                />
              </va-card>
              <va-card title="*Banner Image">
                <va-file-upload
                  type="single"
                  dropzone
                  @input="CheckFile('banner_image')"
                  v-model="banner_image"
                />
              </va-card>
            </div>
            <div class="flex xs12">
              <va-card v-if="movieFlag">
                <div class="flex md12 sm12 xs12">
                  <input type="file" ref="movie" style="display: none;" id="movie" v-on:change="validateVideo($event,'tv')" accept=".mp4"><br>
                  <va-button type="submit" class="my-0" @click="$refs.movie.click()">Upload Movie</va-button>
                  <label>{{ movieName }}</label>
                </div>
              </va-card>
            </div>
            <div class="d-flex justify--center mt-3">
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createContent()">Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateContent()">Update</va-button>
              <va-button type="submit" class="my-0" @click.prevent="list()">Cancel</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { debounce } from 'lodash'
import Datepicker from 'vuejs-datepicker'
Vue.use(vueResource)
export default {
  name: 'org',
  components: { Datepicker },
  beforeCreate () {
    const loader = Vue.$loading.show({ width: 40, height: 40 })
    this.$http.get(config.menu.host + 'content').then(response => {
      loader.hide()
      this.contentData = response.body
      this.isshowForm = false
      this.isshowgrid = true
      this.getAllContent()
    }, error => {
      loader.hide()
      if (error && error.body) {
        Vue.notify({ text: error.body, type: 'error' })
      }
    })
  },
  data () {
    return {
      term: null,
      perPage: '6',
      language_data: [],
      age_data: ['U', 'U/A', 'A'],
      laguage_object: [],
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: false,
      isshowForm: false,
      isCreate: false,
      isUpdate: false,
      liveFlag: false,
      movieFlag: false,
      header_title: '',
      short_code: '',
      title: '',
      language: '',
      url: '',
      url_placeholder: '',
      age_limit: '',
      genres: '',
      description: '',
      type: '',
      actor: '',
      movie: '',
      movieName: '',
      url_type: '',
      director: '',
      releasedate: new Date(),
      disabledDate: { to: null },
      typeErrors: [],
      descriptionErrors: [],
      agelimitErrors: [],
      genresErrors: [],
      titleErrors: [],
      actorErrors: [],
      directorErrors: [],
      languageErrors: [],
      horizontal_image: [],
      vertical_image: [],
      releasedateErrors: [],
      contentData: [],
      urltypeErrors: [],
      urlErrors: [],
      banner_image: [],
      type_obj: [{ id: 'Movie' }, { id: 'Live Channels' }],
      urltype_obj: [{ id: 'rtmp' }, { id: 'rtsp' }, { id: 'hls' }, { id: 'udp' }],
    }
  },
  computed: {
    checkMovie () {
      this.agelimitErrors = this.age_limit ? [] : ['Age Limit is required']
      this.actorErrors = this.actor ? [] : ['Actor is required']
      this.directorErrors = this.director ? [] : ['Director is required']
      this.releasedateErrors = this.releasedate ? [] : ['Releasedate is required']
      return !this.agelimitErrors.length && !this.actorErrors.length && !this.directorErrors.length && !this.releasedateErrors.length
    },
    checkLive () {
      this.urltypeErrors = this.url_type ? [] : ['URL Type is required']
      this.urlErrors = this.url ? [] : ['URL is required']
      return !this.urltypeErrors.length && !this.urlErrors.length
    },
    formReady () {
      return !this.titleErrors.length && !this.descriptionErrors.length && !this.languageErrors.length
    },
    Checkfile_upload () {
      if (this.horizontal_image[0] && this.vertical_image[0] && this.banner_image[0]) {
        return true
      } else {
        Vue.notify({ text: 'please Upload images', type: 'error' })
        return false
      }
    },
    checkVideo () {
    	if (this.movie) {
    		return true
    	} else {
		    Vue.notify({ text: 'please Upload movie', type: 'error' })
		    return false
      	}
    },
    fields () {
      return [{
        name: 'title',
        title: 'Title',
      },
      {
        name: 'description',
        title: 'Description',
      },
      {
        name: 'language',
        title: 'Language',
      }, {
        name: '__slot:status',
        dataClass: 'text-left',
        title: 'Content Status',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      return search(this.term, this.contentData)
    },
  },
  methods: {
  	typeInput () {
  		if (this.type.id == 'Movie') {
  			this.movieFlag = true
  			this.liveFlag = false
  		} else {
  			this.liveFlag = true
  			this.movieFlag = false
  		}
  	},
  	CheckFile (type) {
      if (typeof (type) !== 'undefined') {
        if (type == 'horizontal_image') {
          var image_file = this.horizontal_image[0]
          var image_type = image_file.type.split('/')
          if (!(image_type[1] == 'png' || image_type[1] == 'jpeg')) {
            this.horizontal_image = ''
            Vue.notify({ text: 'support formats are png/jpeg ', type: 'error' })
          }
        }
      }
      //   var image_file;
      //   (type == 'logo') ? image_file = this.logo_file[0] : image_file = this.banner_file[0]
      //   if (image_file !== undefined) {
      //     var file_name = image_file.name.split('.').pop()
      //     if (file_name.toLowerCase() == 'png') {
      //       const img = new Image()
      //       let width = '512'
      //       let height = '512'
      //       if (type === 'banner') {
      //         width = '1280'
      //         height = '720'
      //       }
      //       const reader = new FileReader()
      //       reader.readAsDataURL(image_file)
      //       reader.onload = evt => {
      //         img.onload = () => {
      //           if (img.width == width && img.height == height) {
      //             (type == 'logo') ? this.logo_name = image_file.name : this.banner_name = image_file.name
      //           } else {
      //             (type == 'logo') ? this.logo_file = '' : this.banner_file = ''
      //             Vue.notify({ text: 'please check the images size', type: 'error' })
      //           }
      //         }
      //         img.src = evt.target.result
      //       }
      //     } else {
      //       (type == 'logo') ? this.logo_file = '' : this.banner_file = ''
      //       Vue.notify({ text: 'please check the images Format', type: 'error' })
      //     }
      //   }
      // }
    },
    urlInput (type) {
    	switch (type.id) {
		  case 'rtmp':
		  	this.url_placeholder = 'rtmp:/test.com/movie/test'
		    break
		  case 'hls':
		    this.url_placeholder = 'http:/test.com/test.m3u8'
		    break
	      case 'udp':
		    this.url_placeholder = 'udp://239.2.2.2:1024'
		    break
		  case 'rtsp':
		    this.url_placeholder = 'rtsp://192.168.1.111/test/h264'
		    break
      }
    },
    validateVideo (eve, type) {
      var video = eve.target.files[0]
      var size = eve.target.size
      if ((size / (1024 * 1024)) <= 2000) {
        this.movieName = video.name
        this.movie = video
        return true
      } else {
        alert('please upload video less than 2 GB')
        this.movieName = ''
        this.movie = ''
        return false
      }
    },
    shortCode () {
      var ref = this
      var short
      this.language_object.map(function (item) {
        if (ref.language == item.language) {
          short = item.short_code
        }
      })
      return short
    },
    getAllLanguage () {
      this.$http.get(config.menu.skie_url + 'language/cdn').then(response => {
        this.language_object = (response.body.length > 0) ? response.body : []
        var language_array = []
        if (response.body.length > 0) {
          response.body.map(function (item) {
            language_array.push(item.language)
          })
        } else {
          language_array = []
        }
        this.language_data = language_array
      })
    },
    getAllContent () {
      this.$http.get(config.menu.host + 'content').then(response => {
        this.contentData = (response.body.length > 0) ? response.body : []
        this.isshowgrid = true
      })
    },
    createContent () {
      this.titleErrors = this.title ? [] : ['Title is required']
      this.descriptionErrors = this.description ? [] : ['Description is required']
      this.languageErrors = this.language ? [] : ['Language is required']

      if (!this.formReady) {
        return
      }
      if (this.movieFlag && !this.checkMovie) {
        Vue.notify({ text: 'Please fill the form correctly', type: 'error' })
        return
      }
      if (this.liveFlag && !this.checkLive) {
        Vue.notify({ text: 'Please fill the form correctly', type: 'error' })
        return
      }
      if (!this.Checkfile_upload) {
        return
      }
      if (this.movieFlag && !this.checkVideo) {
        return
      }

      this.short_code = this.shortCode()
      var payload = new FormData()
	    payload.append('type', this.type.id)
      payload.append('title', this.title)
      payload.append('description', this.description)
      payload.append('language', this.language)
      payload.append('director', this.director)
      payload.append('actor', this.actor)
      payload.append('short_code', this.short_code)
      payload.append('url', this.url)
      payload.append('url_type', this.url_type.id)
      payload.append('age_limit', this.age_limit)
      payload.append('releasedate', this.releasedate)
      payload.append('banner_image', this.banner_image[0])
      payload.append('vertical_image', this.vertical_image[0])
      payload.append('horizontal_image', this.horizontal_image[0])
      payload.append('movie', this.movie)
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + 'content', payload).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    updateContent () {
      this.titleErrors = this.title ? [] : ['Title is required']
      this.descriptionErrors = this.description ? [] : ['Description is required']
      this.languageErrors = this.language ? [] : ['Language is required']

      if (!this.formReady) {
        return
      }
      this.short_code = this.shortCode()
      var payload = new FormData()
      payload.append('short_code', this.short_code)
      payload.append('title', this.title)
      payload.append('description', this.description)
      payload.append('language', this.language)
      payload.append('director', this.director)
      payload.append('actor', this.actor)
      payload.append('age_limit', this.age_limit)
      payload.append('releasedate', this.releasedate)
      if(this.banner_image[0]){ payload.append('banner_image', this.banner_image[0]) }
      if(this.vertical_image[0]){ payload.append('vertical_image', this.vertical_image[0]) }
      if(this.horizontal_image[0]){ payload.append('horizontal_image', this.horizontal_image[0]) }
      payload.append('movie', this.movie)
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + 'content/' + this.content_id, payload).then(resp => {
        loader.hide()
        if (resp && resp.body) {
          Vue.notify({ text: resp.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    add () {
      this.getAllLanguage()
      this.header_title = 'Create Content'
      this.type = ''
      this.title = ''
      this.description = ''
      this.language = ''
      this.genres = ''
      this.url = ''
      this.url_type = ''
      this.actor = ''
      this.age_limit = ''
      this.director = ''
      this.movieFlag = false
      this.liveFlag = false
      this.horizontal_image = []
      this.vertical_image = []
      this.banner_image = []
      this.movie = []      
      this.typeErrors = []
      this.titleErrors = []
      this.descriptionErrors = []
      this.languageErrors = []
      this.genresErrors = []
      this.urltypeErrors = []
      this.urlErrors = []
      this.actorErrors = []
      this.directorErrors = []
      this.agelimitErrors = []
      // const today = new Date()
      // const yesterday = new Date()
      // yesterday.setDate(yesterday.getDate() - 1)
      // this.disabledDate.to = yesterday
      this.isshowForm = true
      this.isshowgrid = false
      this.isUpdate = false
      this.isCreate = true
    },
    edit (row) {
      this.getAllLanguage()
      this.header_title = 'Update Content'
      this.content_id = row.content_id
      this.type = row.type
      this.title = row.title
      this.genres = row.genres
      this.language = row.language
      this.description = row.description
      if (this.type == 'Movie') {
      	this.movieFlag = true
      	this.liveFlag = false
      } else {
      	this.movieFlag = false
      	this.liveFlag = true
      }
      this.actor = row.actor
      this.age_limit = row.age_limit
      this.director = row.director
      this.url_type = row.url_type
      this.url = row.url
      this.password = ''
      this.phone_number = row.phone_number
      this.status = row.status ? true : false
      this.movie = ''
      this.isshowgrid = false
      this.isshowForm = true
      this.isCreate = false
      this.isUpdate = true
    },
    list () {
      this.isshowForm = false
      this.isshowgrid = true
      this.getAllContent()
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
}
</script>
<style scoped>
.date_picker_label {
  color: #2c82e0;
  font-size: 0.7rem;
  margin-left: 10px;
  font-weight: bold;
}

</style>
